import { yupResolver } from '@hookform/resolvers/yup';
import { linearize } from 'ksk/hooks/treeUtils';
import { formulaInMathJsFormat } from 'ksk/styledComponents/components/KskFormulaEditor/formulaUtils';
import * as math from 'mathjs';
import { brDate } from 'utils/date-utils';
import * as yup from 'yup';

/*eslint-disable no-template-curly-in-string*/
const yupLocalePtBR = {
  mixed: {
    default: 'Preenchimento inválido',
    required: 'Campo obrigatório',
    oneOf: 'Deve ser um dos seguintes valores: ${values}',
    notOneOf: 'Não pode ser um dos seguintes valores: ${values}',
    notType: 'Formato inválido'
  },
  string: {
    length: 'Deve ter exatamente ${length} caracteres',
    min: 'Deve ter pelo menos ${min} caracteres',
    max: 'Deve ter no máximo ${max} caracteres',
    email: 'Deve ter o formato de um e-mail válido',
    url: 'Deve ter um formato de uma URL válida',
    trim: 'Remova espaços do início ou do fim',
    lowercase: 'Preencha em caixa baixa',
    uppercase: 'Preencha em caixa alta', 
  },
  number: {
    min: 'Deve ser no mínimo ${min}',
    max: 'Deve ser no máximo ${max}',
    lessThan: 'Deve ser menor que ${less}',
    moreThan: 'Deve ser maior que ${more}',
    notEqual: 'Não pode ser igual à ${notEqual}',
    positive: 'Deve ser um número posítivo',
    negative: 'Deve ser um número negativo',
    integer: 'Deve ser um número inteiro',
  },
  date: {
    min: ({min}) => `Não pode ser anterior a ${brDate(min)}`,
    max: ({max}) => `Não pode ser posterior a ${brDate(max)}`,
  },
  array: {
    min: 'Deve conter no mínimo ${min} elemento(s)',
    max: 'Deve conter no máximo ${max} elemento(s)'
  }
}

yup.setLocale(yupLocalePtBR);

const extractNumbers = (str) => str?.replace(/[^0-9]/g,'')
const calcDigitoModulo11 = (str = '', pesos = [], fnResto = (soma) => soma > 9 ? 0 : soma) => {
  /* console.log('--------------------------------');
  console.log('str', str);
  console.log('pesos', pesos) */
  let soma = 0;
  //let log = [];
  for (let indice = str.length - 1, digito; indice >= 0; indice--) {
    digito = Number(str.substring(indice, indice + 1));
    const peso = pesos.length - str.length + indice;
    soma += digito * pesos[peso];
    //log.push(`(${digito}*${pesos[peso]})`)
  }

  const modulo11 = 11 - (soma % 11);

  // Memória de cálculo
 /*  log = [
    `Calculando dígito: `,
    `SOMA = ${log.join('+')} = ${soma}`,
    `(SOMA % 11) = (${soma} ÷ 11) = ${Math.floor(soma/11)}; RESTO = ${soma%11}`,
    `(11 - (SOMA % 11)) = (11 - ${soma % 11}) = ${modulo11}`,
    `DV = ${fnResto(modulo11)}`
  ];
  console.log(log.join('\n')); */

  return fnResto(modulo11);
}

const PESOS_CPF = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2 ];
const PESOS_CNPJ = [ 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2 ];
const PESOS_NUP = [ 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
const PESOS_ID_SICONFI = [ 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

export const validateCPF = (cpf = '', permitirSequencias = false) => {
  const format = /^((\d{3}).(\d{3}).(\d{3})-(\d{2}))|\d{11}.*/;

  if(!cpf){
    return;
  }

  if (!format.test(cpf)) {
    throw new Error('CPF deve conter 11 dígitos numéricos');
  }

  const rawCPF = extractNumbers(cpf);  
  // Regex que testa cpfs com todos os números iguais, ou a sequencia 01234567890
  const sequenciasRegex = new RegExp(`^${rawCPF[0]}{${rawCPF.length}}|01234567890$`);

  if (!permitirSequencias && sequenciasRegex.test(rawCPF)) {
    return false;
  }
  const digito1 = calcDigitoModulo11(rawCPF.substring(0, 9), PESOS_CPF);
  const digito2 = calcDigitoModulo11(rawCPF.substring(0, 9) + digito1, PESOS_CPF);

  if (rawCPF !== rawCPF.substring(0, 9) + digito1 + digito2) {
    throw new Error('CPF Inválido');
  }
}

const validateCNPJ = (cnpj = '') => {
  const format = /^((\d{2}).(\d{3}).(\d{3})\/(\d{4})-(\d{2}))|\d{14}.*/;

  if (!format.test(cnpj)) {
    throw new Error('CNPJ deve conter 14 dígitos numéricos');
  }

  const rawCNPJ = extractNumbers(cnpj);
  const digito1 = calcDigitoModulo11(rawCNPJ.substring(0, 12), PESOS_CNPJ);
  const digito2 = calcDigitoModulo11(rawCNPJ.substring(0, 12) + digito1, PESOS_CNPJ);

  if (rawCNPJ !== rawCNPJ.substring(0, 12) + digito1 + digito2) {
    throw new Error('CNPJ Inválido');
  }
}

const validateNUP = (nup = '') => {
  if(nup==='') return true;

  const format = /^((\d{5}).(\d{6}).(\d{4})-(\d{2}))|\d{17}.*/;

  if (!format.test(nup)) {
    throw new Error('NUP deve conter 17 dígitos numéricos');
  }
  const rawNUP = extractNumbers(nup);
  const digito1 = calcDigitoModulo11(rawNUP.substring(0, 15), PESOS_NUP, (soma) => soma % 10);
  const digito2 = calcDigitoModulo11(rawNUP.substring(0, 15) + digito1, PESOS_NUP, (soma) => soma % 10);

  if (rawNUP !== rawNUP.substring(0, 15) + digito1 + digito2) {
    throw new Error('NUP Inválido');
  }
}

export const validateIdSiconfi = (idSiconfi = '') => {
  
  if(idSiconfi==='') return true;

  const format = /^((\d{4}).(\d{2}).(\d{6})-(\d{2}))|\d{14}.*/;

  if (!format.test(idSiconfi)) {
    throw new Error('Deve conter 14 dígitos numéricos');
  }
  const rawIdSiconfi = extractNumbers(idSiconfi);
  const digito1 = calcDigitoModulo11(rawIdSiconfi.substring(0, 12), PESOS_ID_SICONFI, (soma) => soma % 10);
  const digito2 = calcDigitoModulo11(rawIdSiconfi.substring(0, 12) + digito1, PESOS_ID_SICONFI, (soma) => soma % 10);

  if (rawIdSiconfi !== rawIdSiconfi.substring(0, 12) + digito1 + digito2) {
    throw new Error('Este não é um Identificador Siconfi válido');
  }
}

yup.addMethod(yup.string, "cpf", function (args = '') {
  return this.test(`cpf`, args, function (value) {
    const { path, createError } = this;
    const errorMessage = (typeof args === 'string') ? args : args?.errorMessage;
    const permitirSequencia = (typeof args === 'object') && args?.permitirSequencia;
   
    try {
      validateCPF(value, permitirSequencia);
      return true;
    } catch (e) {
      return createError({ path, message: errorMessage || e.message })
    }
  });
});

yup.addMethod(yup.string, "cnpj", function (errorMessage) {
  return this.test(`cnpj`, errorMessage, function (value) {
    const { path, createError } = this;
    try {
      validateCNPJ(value);
      return true;
    } catch (e) {
      return createError({ path, message: errorMessage || e.message })
    }
  });
});

yup.addMethod(yup.string, "nup", function (errorMessage) {
  return this.test(`nup`, errorMessage, function (value) {
    const { path, createError } = this;
    try {
      validateNUP(value);
      return true;
    } catch (e) {
      return createError({ path, message: errorMessage || e.message })
    }
  });
});

yup.addMethod(yup.string, "idSiconfi", function (errorMessage) {
  return this.test(`idSiconfi`, errorMessage, function (value) {
    const { path, createError } = this;
    try {
      validateIdSiconfi(value);
      return true;
    } catch (e) {
      return createError({ path, message: errorMessage || e.message })
    }
  });
});


yup.addMethod(yup.string, "paramName", function (errorMessage) {
  return this.test(`param-name`, errorMessage, function (value) {
    const { path, createError } = this;

    //window[ 'value'] = value;

    if (value && !(/[a-zA-Z]/).test(value[0]) ) {
      return createError({ path, message: errorMessage || 'Deve iniciar com uma Letra' })
    }

    if (value && value.includes(' ')) {
      return createError({ path, message: errorMessage || 'Não deve conter espaços em branco' })
    }

    if (value && !(/^\w+$/.test(value))) {
      return createError({ path, message: errorMessage || 'Só pode conter letras, números e o conector "_" (underline)' })
    } 

    return true;
  });
});



const isParam = (symbol) => symbol.startsWith('p_');
const isFn  = (symbol) => symbol.startsWith('fn_') || ['IF', 'NOT'].includes(symbol);

const isSis  = (symbol) => symbol.startsWith('sis_');
const isArg  = (symbol) => symbol.startsWith('arg_');
const isRotulo  = (symbol) => !isParam(symbol) && !isFn(symbol) && !isSis(symbol) && !isArg(symbol);

const getSymbols = ({formula}) => {
  try {
    const parsed = math.parse(formula);
    const symbols = [];
    parsed.traverse(v => symbols.push(v))
    return symbols.filter(v => v.__proto__.type === 'SymbolNode') 
  } catch (e) {
    return null;
  }
}

/* const getRotulosMap = ({root, node}) => {
  const rotulos = root?.leaves?.filter(l => !l.isRoot && l.key !== node.key).map(l => l?.data?.formula);
  formulas.reduce((output, formula) => {
    const rotulos

  }, {})
} */

//window['mathJS'] = math;

const mathjsOperators = {
  basicLogical: 'or|and'.split('|'),
  otherLogical: 'xor|bitOr|bitXor|bitAnd'.split('|'),
  relational: 'equal|unequal|smaller|larger|smallerEq|largerEq'.split('|'),
  bitShift: 'leftShift|rightArithShift|rightLogShift|to'.split('|'),
  math: 'add|subtract|multiply|divide|dotMultiply|dotDivide|mod'.split('|'),
  unaryMath: 'unaryPlus|unaryMinus'.split('|'),
  unary:'bitNot|not'.split('|'),
  basicExponentiation:'pow'.split('|'),
  exponentiation:'dotPow|factorial'.split('|'),
  matrix:'transpose'.split('|')
}

const isValidFormula = ({root, node, value}) => {
  const validateRotulos = (identificadores) => {
    const leaves = root?.leaves?.filter(l => !l.isRoot && l.key !== node.key) //.map(l => l?.data?.rotulo);
    const rotulos = leaves.map(l => l?.data?.rotulo);

    const nodeIndex = node.index > 0 ? node.index : node?.parent?.index + linearize(node?.parent).preOrder().length;

    //const rotulosAnteriores = rotulos(l => l?.index < node.index).map(l => l?.data?.rotulo)
    const rotulosPosteriores = leaves.filter(l => l?.index < nodeIndex).map(l => l?.data?.rotulo);

    const nesteQuadro = identificadores.filter(i => isRotulo(i))
    const unknown = [];
    const posteriores = [];
    // eslint-disable-next-line no-unused-expressions
    nesteQuadro?.forEach(i => !rotulos.includes(i) && unknown.push(i))

    // eslint-disable-next-line no-unused-expressions
    nesteQuadro?.forEach(i => !rotulosPosteriores.includes(i) && posteriores.push(i))

    if (unknown.length) {
      const prefix = unknown.length > 1 ? `Os Rótulos` : `O Rótulo`;
      const list = unknown.join(', ');
      const suffix = unknown.length > 1 ? `não foram encontrados` : `não foi encontrado`;

      throw {
        custom: true,
        message : `${prefix} "${list}" ${suffix} neste Quadro.`
      }
    }
  
    if (posteriores.length) {
      const prefix = posteriores.length > 1 ? `Os Rótulos` : `O Rótulo`;
      const list = posteriores.join(', ');
      const suffix = posteriores.length > 1 ? `existem neste Quadro, mas foram definidos` : `existe neste Quadro, mas foi definido`;

      throw {
        custom: true,
        message : `${prefix} "${list}" ${suffix} posteriormente.` +
        `\nReferências a Rótulos devem obedecer a sequência em que forem definidos.`
      }
    }
  };

  const validateOperators = (ops) => {
    const forbidden = [];
    const allowed = [
      ...mathjsOperators.math, 
      ...mathjsOperators.basicLogical,
      ...mathjsOperators.unaryMath, 
      ...mathjsOperators.relational,
      ...mathjsOperators.basicExponentiation
    ]
    // eslint-disable-next-line no-unused-expressions
    ops?.forEach(o => !allowed.includes(o?.fn) && forbidden.push(o?.op));

    if (forbidden.length) {
      const prefix = forbidden.length > 1 ? `Operadores` : `Operador`;
      const list = forbidden.join(', ');
      const suffix = forbidden.length > 1 ? `inválidos encontrados` : `inválido encontrado`;
  
      throw {
        custom: true,
        message : `Fórmula inválida. ${prefix} "${list}" ${suffix}.`
      }
    }
  };

  const validateAssignments = (members) => {
    if (!!members.filter(v => v.__proto__.type === 'AssignmentNode').length) {
      throw {
        custom: true,
        message : `Fórmula inválida. Operador "=" inválido encontrado.`
      }
    }
  }

  const throwUnsupportedFeatures = ({node, parent}) => {
    const {type} = node;
    switch (type) {
      case 'OperatorNode':
        if (node.implicit) {
          throw {
            custom: true,
            message : 'Operadores implícitos não são suportados.'
          }
        } else if (['factorial', 'unaryPlus'].includes(node.fn)) {
          throw {
            custom: true,
            message : `O operador "${({factorial: '! (fatorial)', unaryPlus: '+ (unário)'})[node.fn]}" não é suportado.`
          }
        } else if (['='].includes(node.op)) {
          throw {
            custom: true,
            message : `O operador "${node.op}" não é suportado.`
          }
        }
        break;
      case 'FunctionAssignmentNode':
        throw {
          custom: true,
          message : 'Atribuição de valores à uma função não é suportada. (Operador "=")'
        }
      case 'FunctionNode':
       /*  if (node.name.toUpperCase() === 'IF') {
          throw {
            custom: true,
            message : `Função condicional "IF" ainda não é suportada.`
          }
        } */
        break;
      case 'SymbolNode':
        if ('p_' === node.name.substring(0,2)) {
          throw {
            custom: true,
            message : `Parâmetros de cálculo ainda não são suportados: "${node.name}"`
          }
        }
        if ('sis_' === node.name.substring(0,4) && parent?.type !== 'FunctionNode') {
          throw {
            custom: true,
            message : `Por enquanto variáveis de sistemas só são suportadas enquanto argumento de funções: "${node.name}"`
          }
        }
        if ('arg_' === node.name.substring(0,4) && parent?.type !== 'FunctionNode') {
          throw {
            custom: true,
            message : `Por enquanto variáveis de contexto só são suportadas enquanto argumento de funções: "${node.name}"`
          }
        }
        break;
      case 'AssignmentNode':
        throw {
          custom: true,
          message : 'Atribuição de valores não é suportada. (Operador "=").'
        }
      default: 
        // do nothing
    }
  }

  try {
    const parsed = math.parse(value);
    const members = [];
    parsed.traverse(v => members.push(v))
    const symbols = members.filter(v => v.__proto__.type === 'SymbolNode').map(s => s.name)
    const operators = members.filter(v => v.__proto__.type === 'OperatorNode');

    /*=====================================================================*/
    parsed.traverse((node, path, parent) => { 
      throwUnsupportedFeatures({node, parent});
    });
    /*######################################################################*/
    
    //console.log({members})

    validateRotulos(symbols);
    validateOperators(operators);
    validateAssignments(members);
    
    return true;
  } catch (e) {
    throw {
      cause: e,
      message: e.custom ? e.message : `Fórmula mal formada.`
    };
  }
}

yup.addMethod(yup.string, "formula", function ({root, node, errorMessage}) {
  return this.test(`formula`, errorMessage, function (value) {
    const { path, createError } = this;

    try {
      isValidFormula({root, node, value: formulaInMathJsFormat(value)});
      return true;
    } catch (e) {
      //e.cause && console.log('Erro: ', e.cause);
      return createError({ path, message: errorMessage || e.message })
    }
  });
});

yup.addMethod(yup.mixed, 'metadadosArquivo', function ({required = false} = {}) {
  return this.test('metadadosArquivo', 'Arquivo inválido', function (value) {
    if (value.hasOwnProperty('__error__')) {
      return this.createError({
        path: this.path,
        message: value.__error__
      });
    }

    if (required && (!value || !value.arqNome)) {
      return this.createError({
        path: this.path,
        message: 'Campo obrigatório'
      });
    }

    return true;
  });
});

export const createValidationSchema = yupObjectSchemaDefinition => yup.object().shape(yupObjectSchemaDefinition);
export const createValidationResolver = (yupObjectSchemaDefinition) =>  yupResolver(createValidationSchema(yupObjectSchemaDefinition));
