import GenericHttpService from './GenericHttpService';

const emptyObject = {
   id: null,
   dividaTipo:null,

   devedorTipoFront: null,   
   dividaDestinacao: '',
   devedorInfo: {
    id: null,
    devedorTipo: null,
    tipoEnte: null,
    ente: null,
    pessoaJuridica: null,
    cnpj: null,
    nome: null,
    codIbge: null,
    siglaUF: null
   },
   credor:{
     tipo:null,
     origem:null,
     tipoPj:null,
     tipoEnte:null,
     siglaUF:null,
     identificador:null,
     nome:null
   }

   
};

export default {
  ...GenericHttpService('coc'),
  getEmptyObject: () => ({...emptyObject})
};